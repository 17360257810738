import React, { useEffect, useState } from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import Input from "antd/lib/input";
import Button from "../../../Common/components/Button";
import Col from "antd/lib/col";
import { Cascader, Form, Modal, Row, Typography } from "antd";
import { DatePicker } from "../../../Common/components/DatePicker";
import {
  useAllowSpecialProgramOption,
  useSpecialProgramOptionsList,
} from "../../apiHooks";
import { setFormInputValue } from "../../../../common/helpers";
import { DateRangePicker } from "../../../../common/components";
import ConfirmConfigurations from "../ConfirmConfigurations";

type AddOperationFormProps = {
  user_id: string;
};

const AddSpecialOptionForm: React.FC<AddOperationFormProps> = ({ user_id }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const initialValues = {
    amount: 0,
  };

  const { data, isLoading, isSuccess } = useSpecialProgramOptionsList({
    user_id: user_id,
  });
  const programsAvailableOptions = isSuccess
    ? Object.keys(data.programs).map((project) => {
        return {
          label: project,
          value: project,
          children: Object.keys(data.programs[project]).map((commission) => ({
            label: commission,
            value: commission,
            children: Object.keys(data.programs[project][commission]).map(
              (program) => ({
                label: data.programs[project][commission][program].full_name,
                value: JSON.stringify({
                  id: data.programs[project][commission][program].id,
                  label: data.programs[project][commission][program].full_name,
                }),
              })
            ),
          })),
        };
      })
    : undefined;

  const [
    allowSpecial,
    { isSuccess: isSuccessSpecialProject },
  ] = useAllowSpecialProgramOption();

  const [form] = Form.useForm();

  useEffect(() => {
    isSuccessSpecialProject && form.resetFields();
  }, [isSuccessSpecialProject]);

  const addProgram = (values) => {
    allowSpecial({
      user_id: user_id,
      program_id: JSON.parse(values.program[2]).id,
      amount: values.amount,
      start_date: values.dates?.from,
      stop_date: values.dates?.to,
    });
  };
  const closeModal = () => {
    setIsModalOpened(false);
    form.resetFields();
  };
  return (
    <>
      <Form
        name="add_special_option_form"
        onFinish={addProgram}
        layout="vertical"
        initialValues={initialValues}
        form={form}
      >
        <Row>
          <Col xs={24} md={12} xl={24}>
            <Form.Item
              label={_t("programs")}
              name="program"
              rules={[
                {
                  required: true,
                  message: _t("select_program"),
                },
              ]}
            >
              <Cascader
                options={programsAvailableOptions}
                placeholder={_t("select_special_program")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12} xl={24}>
            <Form.Item
              label={_t("amount")}
              name="amount"
              rules={[
                {
                  required: true,
                  message: _t("input_amount"),
                },
              ]}
            >
              <Input placeholder={_t("amount")} type="number" step="0.01" />
            </Form.Item>
            <Typography.Text
              type="secondary"
              style={{ display: "block", margin: "-5px 0 15px" }}
            >
              {_t("special_option_form_amount_description")}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12} xl={24}>
            <Form.Item label={_t("dates")} name="dates">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "dates",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
                allowEmpty={[true, true]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="text-center">
          <Button
            type="primary"
            title={_t("Save")}
            htmlType="button"
            onClick={() => setIsModalOpened(true)}
          />
        </Form.Item>
        <ConfirmConfigurations
          visible={isModalOpened}
          onOk={() => form.submit()}
          onCancel={() => closeModal()}
          footer={false}
          form={form}
        />
      </Form>
    </>
  );
};

export default AddSpecialOptionForm;
