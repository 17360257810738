import React from "react";
import { DesktopOutlined } from "@ant-design/icons";
import { ReactComponent as IconDashboard } from "../../../images/icons/sidebar/dashboard.svg";
import { ReactComponent as IconPromo } from "../../../images/icons/sidebar/promo.svg";
import { ReactComponent as IconTrackers } from "../../../images/icons/sidebar/trackers.svg";
import { ReactComponent as IconPayments } from "../../../images/icons/sidebar/payments.svg";
import { ReactComponent as IconAffiliates } from "../../../images/icons/sidebar/affiliates.svg";
import { ReactComponent as IconCosts } from "../../../images/icons/sidebar/costs.svg";
import { ReactComponent as IconReports } from "../../../images/icons/sidebar/reports.svg";
import { ReactComponent as IconCommission } from "../../../images/icons/sidebar/commission.svg";
import { ReactComponent as IconFaq } from "../../../images/icons/sidebar/faq.svg";
import { ReactComponent as IconContacts } from "../../../images/icons/sidebar/contacts.svg";
import { ReactComponent as IconUserLogo } from "../../../images/icons/sidebar/user-logo.svg";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { permissions } from "../../../system/constants/roles";

const costsSubMenu = [
  {
    title: "postback_debug",
    path: APP_ROUTES.costs.post_back_debug.register,
  },
];

const manageSubMenu = [
  {
    title: "Users",
    path: APP_ROUTES.manage.users,
  },
  {
    title: "create_user",
    path: APP_ROUTES.manage.users_create,
  },
  {
    title: "managers",
    path: APP_ROUTES.manage.managers,
  },
  {
    title: "Brands",
    path: APP_ROUTES.affiliates_programs.brands,
  },
  {
    title: "Notifications",
    path: APP_ROUTES.manage.notifications.list,
  },
  {
    title: "fraudsters",
    path: APP_ROUTES.manage.fraudsters.list,
  },
];

const contentSubMenu = [
  {
    title: "Faq",
    path: APP_ROUTES.content.faq.list,
  },
  {
    title: "translations",
    path: APP_ROUTES.content.translations.list,
  },
  {
    title: "our_testimonials",
    path: APP_ROUTES.content.testimonials.list,
  },
];

const reportsSubMenu = [
  {
    title: "affiliates_report",
    path: APP_ROUTES.reports.affiliates_report,
  },
  {
    title: "affiliates_geo_report",
    path: APP_ROUTES.reports.affiliates_geo_report,
  },
  {
    title: "affiliate_difference_report",
    path: APP_ROUTES.reports.affiliates_difference_report,
  },
  {
    title: "gamers_ftd_report",
    path: APP_ROUTES.reports.gamers_ftd_report,
  },
  {
    title: "gamers_report",
    path: APP_ROUTES.reports.gamers_report,
  },
  {
    title: "geo_report",
    path: APP_ROUTES.reports.geo_report,
  },
];

export const sidebarItems = [
  {
    title: "Dashboard",
    path: APP_ROUTES.dashboard.general,
    icon: <IconDashboard className="icon-custom" />,
    permission: permissions.user_permission.users,
  },
  {
    title: "Dashboard",
    path: APP_ROUTES.dashboard.admin,
    icon: <IconDashboard className="icon-custom" />,
    permission: permissions.user_permission.admin,
  },
  {
    title: "Promo Materials",
    path: APP_ROUTES.promo.create,
    icon: <IconPromo className="icon-custom" />,
  },
  {
    title: "Trackers",
    path: APP_ROUTES.trackers,
    icon: <IconTrackers className="icon-custom" />,
  },
  {
    title: "Payments",
    path: APP_ROUTES.payments,
    icon: <IconPayments className="icon-custom" />,
  },
  {
    title: "Affiliates",
    path: APP_ROUTES.affiliates,
    icon: <IconAffiliates className="icon-custom" />,
  },
  {
    title: "Costs",
    path: APP_ROUTES.costs.post_back_debug.register,
    icon: <IconCosts className="icon-custom" />,
    permission: permissions.dashboard.header.costs,
  },
  {
    title: "Manage",
    path: APP_ROUTES.manage,
    icon: <IconTrackers className="icon-custom" />,
    subMenu: manageSubMenu,
    permission: permissions.manage.users,
  },
  {
    title: "reports",
    path: APP_ROUTES.content,
    icon: <IconReports className="icon-custom" />,
    subMenu: reportsSubMenu,
    permission: permissions.reports,
  },
  {
    title: "content",
    path: APP_ROUTES.content,
    icon: <IconTrackers className="icon-custom" />,
    subMenu: contentSubMenu,
    permission: permissions.content.translation,
  },
  {
    title: "languages",
    section: "languages",
    path: "change-language",
    icon: <IconTrackers className="icon-custom" />,
  },
  // {
  //   title: "Brands",
  //   path: APP_ROUTES.brands.index,
  //   icon: <IconBrands className="icon-custom-secondary" />,
  //   menuDivider: true,
  // },
  {
    title: "Commission",
    path: APP_ROUTES.commissions.commission,
    icon: <IconCommission className="icon-custom-secondary" />,
    menuDivider: true,
  },
  {
    title: "Faq",
    path: APP_ROUTES.faq.index,
    icon: <IconFaq className="icon-custom-secondary" />,
  },
  {
    title: "Contacts",
    path: APP_ROUTES.contacts,
    icon: <IconContacts className="icon-custom-secondary" />,
  },
];
