import React from "react";
import Modal from "antd/lib/modal/Modal";
import Button from "../../Common/components/Button";
import { _t } from "../../Common/components/InjectIntlContext";
import moment from "moment";

const ConfirmConfigurations = ({ visible, onOk, onCancel, footer, form }) => {
  const selectedProgram = form.getFieldValue("program");
  return (
    <Modal visible={visible} onOk={onOk} onCancel={onCancel} footer={null}>
      <div className="modal-settings-confirm">
        <p className="modal-settings-confirm__title">
          You want to make changes to the basic configuration of the program!
        </p>
        <ul>
          <li>
            <span>Name: </span>
            <span>
              {selectedProgram && JSON.parse(selectedProgram[2])?.label}
            </span>
          </li>
          <li>
            <span>New award will be applied: </span>
            <span>{form.getFieldValue("amount")}</span>
          </li>
          <li>
            <span>Start date of the new configuration: </span>
            <span>
              {`${moment(form.getFieldValue("dates")?.from).format(
                "YYYY-MM-DD"
              )}` || "-/-"}
            </span>
          </li>
        </ul>

        <div className="text-center">
          <Button
            type="primary"
            title={_t("Apply")}
            htmlType="button"
            onClick={() => form.submit()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmConfigurations;
